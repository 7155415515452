import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import Image from '../../assets/img/logo.gif';

const CustomLoading = ({ loading }) => {
  const [step, setStep] = useState(0); // Para controlar el paso actual
  const [finished, setFinished] = useState(false); // Para saber si terminó el proceso

  const steps = [
    'Solicitando turno...',
    'Validando datos...',
    'Verificando disponibilidad...',
    'Aguarde unos segundos...',
  ];

  useEffect(() => {
    if (!loading) {
      // Si loading es false, restablecer el estado y salir
      setStep(0);
      setFinished(false);
      return;
    }

    // Si loading es true, comenzamos el proceso de tres pasos
    const interval = setInterval(() => {
      setStep((prevStep) => {
        // Avanzar al siguiente paso
        if (prevStep < steps.length - 1) {
          return prevStep + 1;
        } else if (loading) {
          return 0;
        } else {
          clearInterval(interval); // Detener el intervalo después del último paso
          setFinished(true); // Marcar como finalizado
          return prevStep; // Mantener el último paso
        }
      });
    }, 1400); // Cambia cada 1.4 segundos

    return () => clearInterval(interval); // Limpiar el intervalo cuando se desmonte
  }, [loading]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {loading ? (
        <>
          <div style={{ marginTop: '5PX', fontSize: '14px' }}>
            Aguarde unos segundos, no refresque la pagina..
          </div>

          <div
            style={{ marginTop: '10PX', fontSize: '18px', fontWeight: 'bold' }}
          >
            {steps[step]}
          </div>

          <div style={{ marginTop: '20px' }}>
            <Tag color={step >= 2 ? 'green' : 'blue'}>
              {finished ? 'OK - Proceso completado' : `Paso ${step + 1}`}
            </Tag>
          </div>

          <div style={{ marginTop: '20px' }}>
            <img
              src={Image} // Usa una imagen animada o SVG
              alt="Loading"
              style={{
                width: '40%',
                animation: 'spin 2s linear infinite',
              }}
            />
          </div>
        </>
      ) : (
        <div>Proceso completado con éxito</div>
      )}
    </div>
  );
};

export default CustomLoading;
